
















































































































































































































































import Vue from 'vue';
import FormSwitch from '@/components/form/form-switch.vue';
import { CASE_STATUS } from '@/constant/constants';
import popUpModal from '@/components/form/pop-up-modal.vue';
import errorMessage from '@/components/form/error-message.vue';

export default Vue.extend({
  name: 'annual-insurance',
  components: {
    FormSwitch,
    popUpModal,
    errorMessage,
  },
  props: ['assesment', 'insuranceData', 'caseStatus', 'isAPIObserver', 'AMSAPISendStatus'],
  watch: {
    totalInsuredSum: 'calculatePrice',
    deutschLandTotalPercentage: 'calculatePrice',
    europaLandTotalPercentage: 'calculatePrice',
    worldWideTotalPercentage: 'calculatePrice',
    changeData: {
      handler(val) {
        if (this.componentLoaded) this.firstPageDataChange();
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    getPercentageSum(): number {
      return (
        this.deutschLandTotalPercentage +
        this.europaLandTotalPercentage +
        this.worldWideTotalPercentage
      );
    },
    isDisableAll(): boolean {
      if (this.isAPIObserver) {
        return true;
      }
      const result =
        this.caseStatus === CASE_STATUS.CONTRACT ||
        this.caseStatus === CASE_STATUS.SUGGESTION ||
        this.caseStatus === CASE_STATUS.CONTRACT_NO_SIGNATURE || this.AMSAPISendStatus !== null ||
        this.caseStatus === CASE_STATUS.OFFER_REQUEST_READY;

      if (result) {
        this.disableAllInputs();
      }
      return result;
    },
    changeData(): any {
      return [this.exhibitionInsured, this.totalInsuredSum, this.deutschLandTotalPercentage, this.europaLandTotalPercentage, this.worldWideTotalPercentage,
        this.deutschLandTotal, this.europaLandTotal, this.worldWideTotal, this.maximumPerExhibition, this.isInPermanentBuildings, this.guardedExibitions, this.artObjects, this.exhibitionStandValue].join();
    },
  },
  data() {
    return {
      exhibitionInsured: true,
      totalInsuredSum: 0,
      deutschLandTotalPercentage: 0,
      europaLandTotalPercentage: 0,
      worldWideTotalPercentage: 0,
      totalInsuredSumPercentage: 100,
      deutschLandTotal: 0,
      europaLandTotal: 0,
      worldWideTotal: 0,
      maximumPerExhibition: 0,
      isInPermanentBuildings: true,
      guardedExibitions: true,
      artObjects: false,
      exhibitionStandValue: null,
      ...this.insuranceData,
      modalOpen: false,
      modalTitle: '',
      modalBody: '',
      componentLoaded: false,
    };
  },
  methods: {
    firstPageDataChange() {
      this.$emit(
        'first-page-data-changed',
      );
    },
    openModal(title: string, body: string) {
      this.modalTitle = title;
      this.modalBody = body;
      this.modalOpen = !this.modalOpen;
    },
    modalClose() {
      this.modalTitle = '';
      this.modalBody = '';
      this.modalOpen = false;
    },
    onDeutschPercentageChange() {
      if (this.getPercentageSum > 100) {
        this.deutschLandTotalPercentage =
          100 - (this.europaLandTotalPercentage + this.worldWideTotalPercentage);
      }
      this.totalInsuredSumPercentage = this.getPercentageSum;
    },
    onEuropaPercentageChange() {
      if (this.getPercentageSum > 100) {
        this.europaLandTotalPercentage =
          100 - (this.deutschLandTotalPercentage + this.worldWideTotalPercentage);
      }
      this.totalInsuredSumPercentage = this.getPercentageSum;
    },
    onWorldWidePercentageChange() {
      if (this.getPercentageSum > 100) {
        this.worldWideTotalPercentage =
          100 - (this.deutschLandTotalPercentage + this.europaLandTotalPercentage);
      }
      this.totalInsuredSumPercentage = this.getPercentageSum;
    },
    calculatePercentage(percent: number, total: number) {
      const product = percent * total;
      return product / 100;
    },
    calculatePrice() {
      this.europaLandTotal = this.calculatePercentage(
        this.europaLandTotalPercentage,
        this.totalInsuredSum,
      );
      this.deutschLandTotal = this.calculatePercentage(
        this.deutschLandTotalPercentage,
        this.totalInsuredSum,
      );
      this.worldWideTotal = this.calculatePercentage(
        this.worldWideTotalPercentage,
        this.totalInsuredSum,
      );
    },
    onSubmit() {
      const data = {
        VersicherungswertAusstellungsstand: this.maximumPerExhibition,
        exhibitionInsured: this.exhibitionInsured,
        Risikoanschrift: 'DE',
        Vertragsdauer: 'Jahresvertrag',
        totalInsuredSum: this.totalInsuredSum,
        isInPermanentBuildings: this.isInPermanentBuildings,
        guardedExibitions: this.guardedExibitions,
        artObjects: this.artObjects,
        scope: [
          {
            scope: 'Deutschland',
            deutschLandTotalPercentage: this.deutschLandTotalPercentage,
          },
          {
            scope: 'Europa',
            europaLandTotalPercentage: this.europaLandTotalPercentage,
          },
          {
            scope: 'Weltweit',
            worldWideTotalPercentage: this.worldWideTotalPercentage,
          },
        ],
        exhibitionStandValue: this.exhibitionStandValue,
      };

      const priceData = {
        BetriebsartAusstellung: this.assesment.operatingMode.BART_TEXT,
        VersicherungswertAusstellungsstand: this.maximumPerExhibition,
        Ausstellungsstand: this.exhibitionInsured,
        Risikoanschrift: 'DE',
        Vertragsdauer: 'Jahresvertrag',
        Versicherungssumme: this.totalInsuredSum,
        FesteGebäude: this.isInPermanentBuildings,
        Bewachung: this.guardedExibitions,
        Kunstgegenstände: this.artObjects,
        Geltungsbereich: [
          {
            Geltungsbereich: 'Deutschland',
            Value: this.deutschLandTotalPercentage,
          },
          {
            Geltungsbereich: 'Europa',
            Value: this.europaLandTotalPercentage,
          },
          {
            Geltungsbereich: 'Weltweit',
            Value: this.worldWideTotalPercentage,
          },
        ],
        exhibitionStandValue: this.exhibitionStandValue,
      };
      this.$emit('data-changed', 'dbf6bf43-a384-4f73-9cf6-bef01e8d5f58', priceData, data);
    },
    setValues(insuranceData: any) {
      if (insuranceData.VersicherungswertAusstellungsstand) {
        this.maximumPerExhibition = insuranceData.VersicherungswertAusstellungsstand;
      }
      if (insuranceData.exhibitionInsured) {
        this.exhibitionInsured = insuranceData.exhibitionInsured;
      }
      if (insuranceData.totalInsuredSum) {
        this.totalInsuredSum = insuranceData.totalInsuredSum;
      }
      if (insuranceData.isInPermanentBuildings) {
        this.isInPermanentBuildings = insuranceData.isInPermanentBuildings;
      }
      if (insuranceData.guardedExibitions) {
        this.guardedExibitions = insuranceData.guardedExibitions;
      }
      if (insuranceData.artObjects) {
        this.artObjects = insuranceData.artObjects;
      }
      if (insuranceData.scope[0].deutschLandTotalPercentage) {
        this.deutschLandTotalPercentage = insuranceData.scope[0].deutschLandTotalPercentage;
      }
      if (insuranceData.scope[1].deutschLandTotalPercentage) {
        this.europaLandTotalPercentage = insuranceData.scope[1].europaLandTotalPercentage;
      }
      if (insuranceData.scope[2].deutschLandTotalPercentage) {
        this.worldWideTotalPercentage = insuranceData.scope[2].worldWideTotalPercentage;
      }
      if (insuranceData.exhibitionStandValue) {
        this.exhibitionStandValue = insuranceData.exhibitionStandValue;
      }
    },
    disableAllInputs() {
      const inputs = document.querySelectorAll('input');
      for (let i = 0; i < inputs.length; i += 1) {
        inputs[i].disabled = true;
      }

      const selects = document.querySelectorAll('select');
      for (let i = 0; i < selects.length; i += 1) {
        selects[i].disabled = true;
      }
    },
  },
  mounted() {
    if (!this.assesment) {
      this.$router.replace('/transport-insurance');
    }
    if (this.insuranceData) {
      this.setValues(this.insuranceData);
    }
    if (this.isDisableAll) {
      this.disableAllInputs();
    }
  },
  beforeUpdate() {
    this.componentLoaded = true;
  },
});
